@use "../config" as *;

.waf-component{
    &.waf-listing{
        &.waf-video-listing{
            @extend %pb-0;
        }
    }
}
.waf-listing{
    &.waf-video-listing{
        .article-list, .loadmore-wrap{
            @extend %d-none;
        }
    }
}

@include mq(col-md){
    .waf-listing{
        .waf-related-list{
            padding-block: var(--space-3);
        }
    }
}

